<template>
<div id="listado-hoteles">
<div class="contaniner">
    <div class="row">
        <div class="col-md-12">
            <h1 class="page-header">Listado de hoteles</h1>
        </div>
        <div class="col-md-12">
            <a href="#" class="btn btn-primary">Nuevo hotel</a>
            <table class="table table-hover table-sprite">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Dirección</th>
                    <th>Orden</th>
                    <th>Activo</th>
                    <th colspan="2">Acciones</th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="inmueble in result.inmuebles" :key="inmueble.id">
                    <td width="10px">{{inmueble.id}}</td>
                    <td>{{inmueble.nombre}}</td>
                    <td>{{inmueble.descripcion}}</td>
                    <td>{{inmueble.direccion}}</td>
                    <td>{{inmueble.orden}}</td>
                    <td>{{inmueble.activo}}</td>
                    <td width="10px"><a href="#" class="btn btn-warning btn-sm">Editar</a></td>
                    <td width="10px"><a href="#" class="btn btn-danger btn-sm" v-on:click="borrarHotel(inmueble.id)">Eliminar</a></td>
                </tr>
            </tbody>
            </table>

        </div>
        <div class="col-md-12">
            <pre>{{$data}}</pre>
        </div>
    </div>
 </div>
 </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'listado-hoteles',
    data() {
      return {
            result:[]
      }
    },
    methods:{
        getHoteles: function(){
            var urlHoteles = "https://oking.eu/api/inmuebles";
            var bearer = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzUyZWY3YzU0NWQ3NGYxNDcxN2M0MmZlZDQ5NzhiYjk1ZGJjM2QxYTMzZDVmNzJjZDU2MzY0N2YxNzY1MjgzY2E2YTYwOTZiMDc1MDRlMDYiLCJpYXQiOjE2MTk3NzMyMjQuNzk0NzIxLCJuYmYiOjE2MTk3NzMyMjQuNzk0NzQ1LCJleHAiOjE2NTEzMDkyMjQuNzY5MjkzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.QsDfStHybl0rnBhxrhxGUBglP_QN_OvwwW0BFmT5S3W_fw0fSYxFhZpJJ1OIu5r0r_u99tv5Z7_pf7q4EHNqT1ouIgeScv6GSHKB52OFn_PuwRgX81swkIjTOTOiId6Svv3RPnsfne1KZmAYzvDbrxmBSiAtL_PjHdWTGLEFBY6LXzjhT2YqusoEJdFxD5VulbZixIx3p39UX73tLTlSkm_aWhEpa0NrRoOoJs640wC2gKGzmbehGMlkXFYhdWQLXq0SQgqWaFcY2mQ4iVTdmj7bHIg9KWMTKsj16-lw56lLY0qHilOiMJQyfTdbTi8qO5MSGa8-EE-6T06vZsGivrkanbfTUIdwGpsiuNWto6pjT7xv1QM3pq9y-IZdm8ius8Py6Z2QJNzq25ehaNDiw2Eo3Pwp8qjUCI4C500Z8h0rP3SpvdC_kOivIRpJFEmj6YziOj_hpjGpjriVYrtfCq6YdP168uBHrhODxpEZkrueIIj4CAFpNsoLE_IkWlyqUZykoFaYNRXl07-LYM0aMVUG1ZiFuhHqRLP9lHqQxSpWZyK9oBX3ZiiecHjfY59-ufvYJWDB5pKNQyNCjJqIW3Q7BgNAQA7cPJ26y4_Gr-8hh3y2ncctXQ6aZZCIsaNRwmWYCzh2GGVRijNdLR_GXaCFwrx7ZIK23VpCaWXXFvo"
            axios.get(urlHoteles,{
                headers:{
                    authorization:bearer}})
                .then(response =>{
                this.result = response.data
            });
        },
        borrarHotel: function (id) {
            var urlHoteles = "https://oking.eu/api/inmuebles";
            var bearer = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzUyZWY3YzU0NWQ3NGYxNDcxN2M0MmZlZDQ5NzhiYjk1ZGJjM2QxYTMzZDVmNzJjZDU2MzY0N2YxNzY1MjgzY2E2YTYwOTZiMDc1MDRlMDYiLCJpYXQiOjE2MTk3NzMyMjQuNzk0NzIxLCJuYmYiOjE2MTk3NzMyMjQuNzk0NzQ1LCJleHAiOjE2NTEzMDkyMjQuNzY5MjkzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.QsDfStHybl0rnBhxrhxGUBglP_QN_OvwwW0BFmT5S3W_fw0fSYxFhZpJJ1OIu5r0r_u99tv5Z7_pf7q4EHNqT1ouIgeScv6GSHKB52OFn_PuwRgX81swkIjTOTOiId6Svv3RPnsfne1KZmAYzvDbrxmBSiAtL_PjHdWTGLEFBY6LXzjhT2YqusoEJdFxD5VulbZixIx3p39UX73tLTlSkm_aWhEpa0NrRoOoJs640wC2gKGzmbehGMlkXFYhdWQLXq0SQgqWaFcY2mQ4iVTdmj7bHIg9KWMTKsj16-lw56lLY0qHilOiMJQyfTdbTi8qO5MSGa8-EE-6T06vZsGivrkanbfTUIdwGpsiuNWto6pjT7xv1QM3pq9y-IZdm8ius8Py6Z2QJNzq25ehaNDiw2Eo3Pwp8qjUCI4C500Z8h0rP3SpvdC_kOivIRpJFEmj6YziOj_hpjGpjriVYrtfCq6YdP168uBHrhODxpEZkrueIIj4CAFpNsoLE_IkWlyqUZykoFaYNRXl07-LYM0aMVUG1ZiFuhHqRLP9lHqQxSpWZyK9oBX3ZiiecHjfY59-ufvYJWDB5pKNQyNCjJqIW3Q7BgNAQA7cPJ26y4_Gr-8hh3y2ncctXQ6aZZCIsaNRwmWYCzh2GGVRijNdLR_GXaCFwrx7ZIK23VpCaWXXFvo"
            axios.delete(urlHoteles + "/" +id,{
                headers:{
                    authorization:bearer}})
                .then(function () {
            //console.log(response);
            });
        }
    },
    mounted(){
        this.getHoteles()
    }
}
</script>

<style scoped>

</style>

